@import url("https://fonts.googleapis.com/css?family=Montserrat:400,700,400italic,700italic");

html,
body {
  overflow-x: hidden;
}

body {
  font-family: "Montserrat", sans-serif;
  position: relative
}

option {
  color: black;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 8px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 8px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}